import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function NewsFormPage() {
  return (
    <Layout>
      <Seo title="News Form" />
      <section className="flex flex-col items-center justify-center py-32 w-full space-y-8">
        <form
          name="Receive News"
          method="POST"
          data-netlify="true"
          action="/success"
          className="flex flex-col justify-center mx-auto p-2 w-full max-w-xl text-left bg-white space-y-4 lg:p-6 lg:space-y-8"
        >
          <input type="hidden" name="form-name" value="Receive News" />

          <h3 className="max-w-lg text-lg font-bold md:text-xl lg:text-2xl">
            Sign up to receive news updates from the Alliance for Automotive
            Innovation.
          </h3>

          <div className="grid gap-8 grid-cols-1 w-full">
            <div>
              <label
                htmlFor="name"
                className="text-sm lg:text-base"
                required="required"
              >
                Name*
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="text-sm lg:text-base"
                required="required"
              >
                Email*
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
              />
            </div>
            <div>
              <label htmlFor="jobTitle" className="text-sm lg:text-base">
                Job Title
              </label>
              <input
                type="text"
                name="jobTitle"
                id="jobTitle"
                className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
              />
            </div>
            <div>
              <label htmlFor="company" className="text-sm lg:text-base">
                Company Name
              </label>
              <input
                type="text"
                name="company"
                id="company"
                className="px-2 w-full h-full border-2 border-gray-300 rounded-sm"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="border-2
						font-bold
						text-black
						border-black
						py-2
						px-4
						rounded-full
						min-w-150
						transition
						focus:outline-none
						hover:bg-black
						hover:text-white
						mt-6"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </section>
    </Layout>
  )
}

export default NewsFormPage
